import {
  GetOfficesResponse,
  OfficeType,
  PermissionProfileRequest,
  PermissionProfileResponse,
  WhoIsResponse,
} from 'shared-types';
import remote from '.';

export const getOffices = async (): Promise<GetOfficesResponse[]> => {
  const url = `/backoffice/offices`;
  const { data } = await remote.get<GetOfficesResponse[]>(url);
  return data;
};

export const changeUserOffice = async (newOffice: number): Promise<void> => {
  const url = `/user/change-office`;
  await remote.patch(url, { new_office: newOffice });
};

export const createOffice = async (payload: {
  officeName: string;
  inherit_profile_ids: number[];
  office_type: OfficeType;
  from_office?: string;
}): Promise<{ office_id: number }> => {
  const url = `/office/${encodeURIComponent(payload.officeName)}`;
  const ret = await remote.put<{ office_id: number }>(url, {
    inherit_profile_ids: payload.inherit_profile_ids,
    from_office: Number(payload.from_office),
    office_type: payload.office_type,
  });
  return ret.data;
};
export type UpsertBackofficeProfileRequest = PermissionProfileRequest & {
  permission_profile_id?: number;
};
export const upsertBackofficeProfile = async (
  payload: UpsertBackofficeProfileRequest,
): Promise<PermissionProfileResponse> => {
  const { data } = await remote.put<PermissionProfileResponse>('backoffice/profile', payload);
  return data;
};
export async function getManagedProfiles(): Promise<PermissionProfileResponse[]> {
  const { data } = await remote.get<PermissionProfileResponse[]>('backoffice/profiles');
  return data;
}
export async function getCreditorByEmail(email: string): Promise<WhoIsResponse> {
  const { data } = await remote.get<WhoIsResponse>(`backoffice/whois?email=${email}`);
  return data;
}

export async function getManagedProfile(
  profileId: string | undefined,
): Promise<PermissionProfileResponse> {
  const { data } = await remote.get<PermissionProfileResponse>(`backoffice/profile/${profileId}`);
  return data;
}

export async function deleteManagedProfiles(profileId: string | number): Promise<void> {
  await remote.delete<PermissionProfileResponse>(`backoffice/profile/${profileId}`);
}
