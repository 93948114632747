import { Container as MuiContainer } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

export const VersionContainer = styled.div`
  text-align: center;
  opacity: 0.6;
`;

export const Container = styled(MuiContainer)`
  display: flex;
  position: absolute;
  left: 50%;
  flex-direction: column;
  width: fit-content;
  padding-top: 4rem;
` as typeof MuiContainer;

const spin = keyframes`
  0% {
    transform: rotate(230deg);
  }
  100% {
    transform: rotate(590deg);
  }
`;

const orbit = keyframes`
  0% {
    transform: rotate(0deg) translateX(4px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(4px) rotate(-360deg);
  }
`;

export const LoaderWrapper = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Circle = styled.div`
  width: 70px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px #F59E18 inset;
  border-radius: 50%;
  position: absolute;
  top: 30%;
  left: 29%;
  animation: ${orbit} 5s infinite;
`;

export const Loader = styled.div`
  width: 70px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px #F59E18 inset;
  border-radius: 50%;
  position: relative;
  animation: ${orbit} 3s linear infinite;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: calc(100% - 3px);
    left: calc(50% - 2.5px);
    box-shadow: inherit;
    width: 25px;
    aspect-ratio: 1;
    border-radius: 50%;
    transform-origin: 30% -22px;
    animation: ${spin} 1.7s linear infinite;
  }

  &::after {
    animation-delay: -0.75s;
  }
`;
