import { ONE_MINUTE_STALE_TIME } from 'constants/reactQuery';
import useSelector from 'modules/default-selector';
import { useMutation, useQuery } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'service/global/types';
import {
  BackendSignRequest,
  CreatePacketItemRequest,
  CreatePacketsRequest,
  GetPacketsQueryParams,
  GetSignersResponse,
  ListPacketsResponse,
  PacketResponse,
  PacketTypeEnum,
  PatchPacketRequest,
  PatchPacketResponse,
  UsersClaims,
} from 'shared-types';
import { useGetClaims } from 'utils/claim';
import { getUserLocation } from 'utils/geolocation';
import Backend from '..';
import {
  makeAgreementsSignersKeys,
  makeGetAgreementByIdKeys,
  makeGetAgreementsKey,
  makePolicyAgreementsToSignKeys,
} from './keys';

export const useGetPolicyAgreementsToSign = (opts?: UseQueryOptions<ListPacketsResponse>) => {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);
  const { hasClaims } = useGetClaims();
  return useQuery(
    makePolicyAgreementsToSignKeys(officeId),
    async (): Promise<ListPacketsResponse> => {
      const { data } = await Backend.get(
        `/offices/${officeId}/document-signer/packets/${PacketTypeEnum.POLICY_AGREEMENT}/to-sign`,
      );
      return data;
    },
    {
      enabled: !!officeId && hasClaims([UsersClaims.POLICY_AGREEMENT_MODULE]),
      staleTime: ONE_MINUTE_STALE_TIME * 5,
      refetchOnWindowFocus: true,
      ...opts,
    },
  );
};

export const useGetPolicyAgreements = (
  filters?: GetPacketsQueryParams,
  opts?: UseQueryOptions<ListPacketsResponse>,
) => {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);
  const { hasClaims } = useGetClaims();

  return useQuery(
    makeGetAgreementsKey(officeId, filters),
    async (): Promise<ListPacketsResponse> => {
      const { data } = await Backend.get(`/offices/${officeId}/document-signer/packets`, {
        params: filters,
      });
      return data;
    },
    {
      enabled: !!officeId && hasClaims([UsersClaims.POLICY_AGREEMENT_MODULE]),
      staleTime: ONE_MINUTE_STALE_TIME * 5, // Cache for 5 minutes
      ...opts, // Optional overrides
    },
  );
};
export const useGetAgreementById = (
  {
    agreementId,
  }: {
    agreementId: string | undefined;
  },
  opts?: UseQueryOptions<PacketResponse>,
) => {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);

  const getSelectedPacket = async (): Promise<PacketResponse> => {
    const { data } = await Backend.get(
      `/offices/${officeId}/document-signer/packets/${agreementId}`,
    );
    return data;
  };

  return useQuery(
    makeGetAgreementByIdKeys(officeId, agreementId),
    getSelectedPacket,

    {
      enabled: !!agreementId,
      staleTime: ONE_MINUTE_STALE_TIME * 5,
      ...opts,
    },
  );
};

export const useSignAgreement = (opts?: UseMutationOptions<BackendSignRequest>) => {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);
  const signDocumentRequest = async ({
    packetId,
  }: {
    packetId: number;
  }) => {
    const userPosition = await getUserLocation();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    const { data } = await Backend.post(
      `/offices/${officeId}/document-signer/packets/${packetId}/sign`,
      {
        latitude: userPosition?.coords.latitude?.toString() ?? undefined,
        longitude: userPosition?.coords.longitude?.toString() ?? undefined,
        timezone,
      } as BackendSignRequest,
    );

    return data;
  };
  return useMutation(signDocumentRequest, opts);
};

export const useGetAgreementSigners = (
  { agreementId }: { agreementId?: number },
  opts?: UseQueryOptions<GetSignersResponse>,
) => {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);

  async function getAgreementSigners() {
    const { data } = await Backend.get(
      `/offices/${officeId}/document-signer/packets/${agreementId}/signers`,
    );
    return data;
  }

  return useQuery(makeAgreementsSignersKeys(officeId, agreementId!), getAgreementSigners, {
    enabled: !!(officeId && agreementId),
    staleTime: ONE_MINUTE_STALE_TIME * 3,
    ...opts,
  });
};

interface EditAgreementParams {
  officeId: number;
  agreementId: number;
  payload: PatchPacketRequest;
}

export const useEditAgreement = (opts?: UseMutationOptions<PatchPacketResponse>) => {
  const editAgreement = async ({
    officeId,
    agreementId,
    payload,
  }: EditAgreementParams): Promise<PatchPacketResponse> => {
    const { data } = await Backend.patch(
      `/offices/${officeId}/document-signer/packets/${agreementId}`,
      payload,
    );

    return data;
  };
  return useMutation(editAgreement, opts);
};

export function useCreateAgreement(opts?: UseMutationOptions<PacketResponse>) {
  const createAgreementRequest = async ({
    officeId,
    payload,
  }: {
    officeId: number;
    payload: CreatePacketsRequest;
  }) => {
    const { data } = await Backend.post(`/offices/${officeId}/document-signer/packets`, payload);
    return data;
  };
  return useMutation(createAgreementRequest, opts);
}

export const useDeleteAgreements = (opts?: UseMutationOptions<void>) => {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);

  const deleteAgreements = async ({
    packet_ids,
  }: {
    packet_ids: number[];
  }) => {
    await Backend.delete(`/offices/${officeId}/document-signer/packets`, {
      data: { packet_ids },
    });
  };

  return useMutation(deleteAgreements, opts);
};
