import { AxiosError } from 'axios';
import { showErrorModal } from 'modules/ui';
import ROUTES from 'pages/routes-enum';
import { useEffect, useState } from 'react';
import { QueryOptions, useMutation, useQuery } from 'react-query';
import {
  changeUserOffice,
  createOffice,
  deleteManagedProfiles,
  getManagedProfiles,
  getOffices,
} from 'service/backoffice.service';
import { QueryPrefixes, queryClient } from 'service/query.client';
import { GetOfficesResponse, PermissionProfileResponse } from 'shared-types';
import { errorStore } from 'stores/errorStore';

type GetOffices = {
  loading: boolean;
  offices: GetOfficesResponse[];
};

export const useGetOffices = (): GetOffices => {
  const [loading, setLoading] = useState(false);
  const [offices, setOffices] = useState<GetOffices['offices']>([]);

  useEffect(() => {
    const fetchOffices = async () => {
      setLoading(true);
      try {
        const allOffices = await getOffices();
        setOffices(allOffices);
      } catch (err) {
        errorStore.errorSink(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOffices();
  }, []);

  return { loading, offices };
};

type ChangeOffice = {
  changeOffice: (newOffice: number) => Promise<void>;
};

export const useChangeOffice = (): ChangeOffice => {
  const changeOffice = async (newOffice: number) => {
    try {
      await changeUserOffice(newOffice);
      window.location.href = ROUTES.REFRESH_USER;
    } catch (error) {
      errorStore.errorSink(error);
    }
  };
  return { changeOffice };
};

export const useCreateOffice = () => {
  const { changeOffice } = useChangeOffice();
  const mutation = useMutation(createOffice, {
    onSuccess: async ({ office_id }) => {
      await changeOffice(office_id);
    },
    onError: (err: AxiosError) => {
      showErrorModal({ heading: 'Erro ao criar escritório', body: err.message });
    },
  });

  return mutation;
};

export function useGetManagedProfiles(opts?: QueryOptions<PermissionProfileResponse[]>) {
  return useQuery([QueryPrefixes.GET_MANAGED_PROFILES], () => getManagedProfiles(), opts);
}

export function useDeleteManagedProfiles(opts?: QueryOptions<void>) {
  return useMutation(deleteManagedProfiles, {
    onSuccess: (_, profileId) => {
      queryClient.setQueryData<PermissionProfileResponse[]>(
        [QueryPrefixes.GET_MANAGED_PROFILES],
        previousData => {
          if (!previousData) return [];
          return previousData.filter(p => p.id !== Number(profileId));
        },
      );
    },
    ...opts,
  });
}
